<template>
  <div class="user-action-table-container">
    <a-table
      class="m_table_cust"
      size="middle"
      :pagination="false"
      :rowKey="(record, index) => index"
      :data-source="dataList"
      tableLayout="fixed"
      :loading="isLoading"
      bordered
    >
      <!-- 日期 -->
      <a-table-column align="left" dataIndex="date" :width="100">
        <span slot="title">日期</span>
      </a-table-column>
      <!-- DAU -->
      <a-table-column align="left" dataIndex="dau" :width="100">
        <span slot="title">DAU</span>
      </a-table-column>

      <!-- DEU -->
      <a-table-column align="left" dataIndex="deu" :width="100">
        <span slot="title">DEU</span>
      </a-table-column>
      <!-- 渗透率 -->
      <a-table-column align="left" dataIndex="permeability" :width="100">
        <span slot="title">渗透率</span>
        <template slot-scope="permeability"> {{ permeability }}% </template>
      </a-table-column>
      <!-- 展示 / DAU -->
      <a-table-column align="left" dataIndex="aipau" :width="100">
        <span slot="title">展示 / DAU</span>
      </a-table-column>
      <!-- 展示 / DEU -->
      <a-table-column align="left" dataIndex="aipu" :width="100">
        <span slot="title">展示 / DEU</span>
      </a-table-column>
      <!-- ARPDAU -->
      <a-table-column align="left" dataIndex="arpu" :width="100">
        <span slot="title">ARPDAU</span>
      </a-table-column>
      <!-- ARPDEU -->
      <a-table-column align="left" dataIndex="arpDeu" :width="100">
        <span slot="title">ARPDEU</span>
      </a-table-column>
    </a-table>
    <a-pagination
      class="pagination"
      v-if="dataList.length > 0"
      show-size-changer
      :current="searchquery.page"
      :total="total"
      :show-total="(total) => `共 ${total} 条`"
      @change="handleChangePage"
      @showSizeChange="onShowSizeChange"
      :pageSize="searchquery.limit"
    />
    <m-empty v-else style="height: 40vh">
      <template #description>暂无数据 </template>
    </m-empty>
  </div>
</template>

<script>
import { userActionReport } from '@/api/reportdatas'
import { numFormat } from '@/utils/dealNumber'
export default {
  data () {
    return {
      dataList: [],
      isLoading: false,
      searchquery: {
        page: 1,
        limit: 10
      },
      total: 0
    }
  },
  methods: {
    dealquery (query) {
      this.searchquery.page = 1
      this.searchquery = { ...this.searchquery, ...query }
      this.getTableData()
    },
    async getTableData () {
      this.isLoading = true
      const resp = await userActionReport(this.searchquery)
      this.dataList = resp.data ? resp.data.items : []
      this.total = resp.data ? resp.data.total : 0
      this.dataList.map((item) => {
        item.dau = numFormat(item.dau, 3, '')
        item.deu = numFormat(item.deu, 3, '')
        item.aipau = numFormat(item.aipau, 3, '')
        item.aipu = numFormat(item.aipu, 3, '')
        item.arpu = numFormat(item.arpu, 3, '')
        item.arpDeu = numFormat(item.arpDeu, 3, '')
      })
      this.isLoading = false
    },
    // 修改page
    handleChangePage (page) {
      this.searchquery.page = page
      this.getTableData()
    },
    // 修改limit
    onShowSizeChange (current, size) {
      this.searchquery.page = 1
      this.searchquery.limit = size
      this.getTableData()
    }
  }
}
</script>

<style lang="less">
.user-action-table-container {
  margin-top: 10px;
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  .pagination {
    padding: 10px 0;
  }
}
</style>
